import domReady from '@roots/sage/client/dom-ready';
import iframeResize from '@iframe-resizer/parent';
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

import { offCanvasElement } from './components/off-canvas-element';

/**
 * Alpine instance
 */
window.Alpine = Alpine;
Alpine.plugin(focus);
Alpine.start();

/**
 * Application entrypoint
 */
domReady(async () => {
  // offCanvasElement();
  /**
   * iframe-resizer - needed to correctly size the Pardot form iframe
   * @see {@link https://iframe-resizer.com/setup/parent/}
   */
  iframeResize({ license: '1jy4dww5qzv-s54r73oxcn-v59f4kfgfz', waitForLoad: true }, '#pardotIframe');
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
